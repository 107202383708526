import React from 'react';
import { Form, Button, ProgressBar } from 'components';

const Step4 = () => {
  return (
    <div className="register__step">
      <h2 className="register__step-title">Thank you for verifying</h2>
      <h2 className="register__step-subtitle">
        Set a password for your Voicio account.
      </h2>
      <Form noValidate>
        <Form.Group className="mb-3">
          <Form.Input
            className="text-center"
            type="password"
            placeholder="Password"
            round
            required
          />
        </Form.Group>
        <Form.Group className="mb-7">
          <Form.Input
            className="text-center"
            type="password"
            placeholder="Confirm Password"
            round
            required
          />
        </Form.Group>
        <Button
          variant="secondary"
          type="button"
          large
          round
          className="mb-5"
          sizeX={'182px'}
        >
          Submit&nbsp;&nbsp; <i className="fas fa-long-arrow-alt-right"></i>
        </Button>
      </Form>
      <ProgressBar steps={4} focus={3} />
    </div>
  );
};

export default Step4;
