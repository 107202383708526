import React from 'react';
import { useStep } from 'react-hooks-helper';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';

const steps = [
  { id: 'step1' },
  { id: 'step2' },
  { id: 'step3' },
  { id: 'step3' },
];

const PhoneFlow = ({ setFlow }) => {
  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;
  const props = { setFlow, navigation };

  const renderStep = (id) => {
    switch (id) {
      case 'step1':
        return <Step1 {...props} />;
      case 'step2':
        return <Step2 />;
      case 'step3':
        return <Step3 />;
      case 'step4':
        return <Step4 />;
      default:
        return null;
    }
  };

  return (
    <>
      {renderStep(id)}
    </>
  );
};

export default PhoneFlow;
