import React, { useState } from 'react';
import { useRecoilStateLoadable } from 'recoil';
import { navigate } from 'gatsby';
import {
  AuthPageWrapper, Link, 
  BrandLogo, Button 
} from 'components';
import { EmailFlow, PhoneFlow, SocialFlow } from 'templates/authentication/register';
import { AuthUserState } from 'data/auth';
import EmailIcon from 'assets/image/icons/register/email.svg';
import VoiceIcon from 'assets/image/icons/register/voice.svg';

const Register: React.VFC = () => {
  const [flow, setFlow] = useState(null);
  const [userLoadable] = useRecoilStateLoadable(AuthUserState);
  if (userLoadable.state === 'hasValue' && userLoadable?.contents?.authenticated) {
    navigate('/dashboard');
    return null;
  }

  const renderFlow = () => {
    switch (flow) {
      case 'email':
        return <EmailFlow setFlow={setFlow} />;
      case 'phone':
        return <PhoneFlow setFlow={setFlow} />;
      case 'social':
        return <SocialFlow setFlow={setFlow} />;
      default:
        return null;
    }
  };

  return (
    <AuthPageWrapper>
      <Link to='/'>
        <BrandLogo className='register__logo' />
      </Link>
      <div className='register__steps'>
        {flow ? (
          <>
            {renderFlow()}
          </>
        ) : (
          <div className='register__step'>
            <h2 className='register__step-title'>Create an account</h2>
            <Button
              variant='secondary'
              large
              round
              fontWeight={500}
              sizeX={'276px'}
              className='register__step-btn mb-2'
              onClick={() => setFlow('email')}
            >
              <EmailIcon className='btn-img' /> Sign up with email
            </Button>
            <Button
              variant='secondary'
              large
              round
              fontWeight={500}
              sizeX={'276px'}
              className='register__step-btn mb-4'
              onClick={() => setFlow('phone')}
            >
              <VoiceIcon className='btn-img' /> Sign up with voice AI
            </Button>
            <div className='register__step-separator'> OR </div>
            <div className='register__step-signup-option'>
              <span
                className='register__step-anchor register__step-signup-option-anchor'
                onClick={() => setFlow('social')}
              >
                Other sign up methods
              </span>
            </div>
            <p className='register__step-terms'>
              By signing up I agree to the Voicio {' '}
              <Link
                to='#'
                className='register__step-anchor register__step-terms-anchor'
              >
                {'Terms & conditions'}
              </Link>
            </p>
            <div className="register__step-register-option mt-2">
              Already have an account?&nbsp;&nbsp;
              <Link
                to="/login"
                className="text-decoration-underline"
              >
                Log In
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className='register__footer'>
        © {(new Date().getFullYear())} Voicio. Alright Reserved.
      </div>
    </AuthPageWrapper>
  );
};

export default Register;
