import React from 'react';
import { Link, Button } from 'components';
import FacebookIcon from 'assets/image/icons/register/facebook.svg';
import GoogleIcon from 'assets/image/icons/register/google.svg';
import AppleIcon from 'assets/image/icons/register/apple.svg';
import EmailIcon from 'assets/image/icons/register/email.svg';
import VoiceIcon from 'assets/image/icons/register/voice.svg';

const Step1 = ({ setFlow, navigation }) => {
  const handleSocial = (): void => {
    navigation.next();
  };

  return (
    <div className="register__step">
      <h2 className="register__step-title">Create an account</h2>
      <h2 className="register__step-subtitle">Other Sign Up Methods</h2>
      <Button
        variant="custom"
        large
        round
        className="register__step-btn mb-2"
        backgroundColor={'#3B5997'}
        hoverBackgroundColor={'#4968A9'}
        textColor={'#FFFFFF'}
        hoverTextColor={'#FFFFFF'}
        sizeX={'276px'}
        onClick={handleSocial}
      >
        <FacebookIcon className="btn-img" /> Sign up with Facebook
      </Button>
      <Button
        variant="custom"
        large
        round
        className="register__step-btn mb-2"
        backgroundColor={'#FFFFFF'}
        hoverBackgroundColor={'#FAFAFA'}
        borderColor={'#999999'}
        hoverBorderColor={'#999999'}
        textColor={'#000000'}
        hoverTextColor={'#000000'}
        sizeX={'276px'}
        onClick={handleSocial}
      >
        <GoogleIcon className="btn-img" /> Sign up with Google
      </Button>
      <Button
        variant="custom"
        large
        round
        className="register__step-btn mb-3"
        backgroundColor={'#8A9DA4'}
        hoverBackgroundColor={'#919EA3'}
        textColor={'#FFFFFF'}
        hoverTextColor={'#FFFFFF'}
        sizeX={'276px'}
        onClick={handleSocial}
      >
        <AppleIcon className="btn-img" /> Sign up with Apple
      </Button>
      <div className="register__step-separator"> OR </div>
      <Button
        variant="secondary"
        large
        round
        fontWeight={500}
        sizeX={'276px'}
        className="register__step-btn mt-2 mb-2"
        onClick={() => setFlow('email')}
      >
        <EmailIcon className="btn-img" /> Sign up with email
      </Button>
      <Button
        variant="secondary"
        large
        round
        fontWeight={500}
        sizeX={'276px'}
        className="register__step-btn mb-4"
        onClick={() => setFlow('email')}
      >
        <VoiceIcon className="btn-img" /> Sign up with voice AI
      </Button>
      <p className="register__step-terms">
        By signing up I agree to the{' '}
        <Link
          to="#"
          className="register__step-anchor register__step-terms-anchor"
        >
          {'Terms & conditions'}
        </Link>
      </p>
      <div className="register__step-register-option mt-2">
        Already have an account?&nbsp;&nbsp;
        <Link
          to="/login"
          className="text-decoration-underline"
        >
          Log In
        </Link>
      </div>
    </div>
  );
};

export default Step1;
