import React from 'react';
import PhoneInput from 'react-phone-input-2';
import { Form, Button, ProgressBar } from 'components';

const Step1 = ({ setFlow, navigation }) => {
  const { next } = navigation;

  return (
    <div className="register__step">
      <h2 className="register__step-title">Create an account</h2>
      <h2 className="register__step-subtitle">What is your phone number?</h2>
      <Form noValidate>
        <Form.Group className="mb-4">
          <PhoneInput
            country={'us'}
            inputProps={{ required: true }}
            placeholder="Your phone number"
          />
        </Form.Group>
        <Button
          variant="secondary"
          type="button"
          large
          round
          className="mb-5"
          sizeX={'182px'}
          onClick={next}
        >
          Continue&nbsp;&nbsp; <i className="fas fa-long-arrow-alt-right"></i>
        </Button>
      </Form>
      <div className="register__step-signup-option">
        <span
          className="register__step-anchor register__step-signup-option-anchor"
          onClick={() => setFlow('social')}
        >
          Other sign up methods
        </span>
      </div>
      <ProgressBar steps={4} focus={1} />
    </div>
  );
};

export default Step1;
