import React from 'react';
import { Form, Button, ProgressBar } from 'components';

const Step3 = () => {
  return (
    <div className="register__step">
      <h2 className="register__step-title">Create an account</h2>
      <Form noValidate>
        <Form.Group className="mb-3">
          <Form.Input
            className="text-center"
            placeholder="First name"
            round
            append={<i className="fas fa-address-card"></i>}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Input
            className="text-center"
            placeholder="Last name"
            round
            append={<i className="fas fa-address-card"></i>}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Input
            className="text-center"
            placeholder="Your profession"
            append={<i className="fas fa-briefcase"></i>}
            round
            required
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Form.Input
            className="text-center"
            type="email"
            placeholder="Email"
            round
            append={<i className="fas fa-envelope"></i>}
            required
          />
        </Form.Group>
        <Button
          variant="secondary"
          type="button"
          large
          round
          className="mb-5"
          sizeX={'182px'}
        >
          Continue&nbsp;&nbsp; <i className="fas fa-long-arrow-alt-right"></i>
        </Button>
      </Form>
      <ProgressBar steps={4} focus={3} />
    </div>
  );
};

export default Step3;
