import React from 'react';
import { Form, Button, ProgressBar } from 'components';
import InvalidIcon from 'assets/image/icons/register/invalid.svg';

type Props = {
  passwords: any,
  isValidated: boolean,
  isMatched: boolean,
  isValidLength: boolean,
  hasSpecialChar: boolean,
  handleChange: (event: any) => void,
  handleSubmit: (event: any) => void,
  username: string
}
const Step3: React.FC<Props> = ({
  passwords,
  isValidated,
  isMatched,
  isValidLength,
  hasSpecialChar,
  handleChange,
  handleSubmit,
  username
}) => {
  const isValid = isMatched && isValidLength && hasSpecialChar;

  return (
    <div className="register__step">
      <h2 className="register__step-title">Create an account</h2>
      <h2 className="register__step-subtitle">
        Set a password for your Voicio account.
      </h2>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <input type='username'
            style={{ display: 'none' }}
            value={username} />
          <Form.Input
            className="text-center"
            name="password"
            type="password"
            placeholder="Password"
            value={passwords.password || ''}
            isValid={isValidated && isValid}
            onChange={handleChange}
            round
            required
          />
        </Form.Group>
        <Form.Group className="mb-4">
          <Form.Input
            className="text-center"
            name="passwordConfirmation"
            type="password"
            placeholder="Repeat your password"
            value={passwords.passwordConfirmation || ''}
            isValid={isValidated && isValid}
            onChange={handleChange}
            round
            required
          />
        </Form.Group>
        <div className="register__step-error-guide">
          {isValidated && !isValidLength && (
            <p><InvalidIcon /> Needs to be at least 6 characters</p>
          )}
          {isValidated && !hasSpecialChar && (
            <p><InvalidIcon /> Needs to have at least one special character</p>
          )}
          {isValidated && !isMatched && (
            <p><InvalidIcon /> Passwords do not match</p>
          )}
        </div>
        <Button
          variant="secondary"
          large
          round
          className="mb-5"
          sizeX={'182px'}
          disabled={!isValid}
        >
          Submit&nbsp;&nbsp; <i className="fas fa-long-arrow-alt-right"></i>
        </Button>
      </Form>
      <ProgressBar focus={3} />
    </div>
  );
};

export default Step3;
