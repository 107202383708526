import React, { useState } from 'react';
import { useStep } from 'react-hooks-helper';
import { useRecoilStateLoadable } from 'recoil';
import { useMutation } from 'react-query';
import { navigate } from 'gatsby';
import { signUp, validateEmail, AuthUserState } from 'data/auth';
import { Loader } from 'components';
import { useForm, usePasswordValidation } from 'components/hooks';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

const steps = [
  { id: 'step1' },
  { id: 'step2' },
  { id: 'step3' },
];

const validateStep1 = (values) => {
  const errors: any = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }

  return errors;
};

const validateStep2 = (values) => {
  const errors: any = {};
  if (!values.first_name) {
    errors.first_name = 'First name is required';
  }
  if (!values.last_name) {
    errors.last_name = 'Last name is required';
  }
  if (!values.title) {
    errors.title = 'Profession is required';
  }
  if (!values.phone) {
    errors.phone = 'Phone number is required';
  }
  if (!values.terms) {
    errors.terms = 'You need to agree Terms & Conditions';
  }

  return errors;
};

interface IFormErrors {
  email?: string;
  [key: string]: string;
}

const EmailFlow = ({ setFlow }) => {
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [passwords, setPasswords] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [formErrors, setFormErrors] = useState({} as IFormErrors);
  const [userLoadable, setUser] = useRecoilStateLoadable(AuthUserState);
  if (userLoadable.state === 'hasValue' && userLoadable?.contents?.authenticated) {
    navigate('/dashboard');
    return;
  }

  const { step, navigation } = useStep({ initialStep: 0, steps });
  const { id } = step;

  const mutation = useMutation(signUp, {
    onSuccess: (user) => {
      if (user) {
        setUser(user);
        navigate('/verify-email');
      }
    },
  });

  const validateEmailMutation = useMutation(validateEmail, {
    onSuccess: (response) => {
      if (response) {
        navigation.next();
      } else {
        setFormErrors({
          email: 'Email already in use.'
        });
      }
    },
  });

  const sendValidateEmail = (values) => {
    validateEmailMutation.mutate(values.email);
  };
  const stepProps1 = useForm(sendValidateEmail, validateStep1);

  const onMove = () => {
    navigation.next();
  };
  const stepProps2 = useForm(onMove, validateStep2);

  const {
    isMatched,
    isValidLength,
    hasSpecialChar,
  } = usePasswordValidation({
    password: passwords.password,
    passwordConfirmation: passwords.passwordConfirmation,
    requiredLength: 8,
  });

  const handleChange = (event): void => {
    event.persist();

    setIsValidated(true);
    setPasswords(values => ({
      ...values,
      [event.target.name]: event.target.value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isValidated && isMatched && isValidLength && hasSpecialChar) {
      const newUser = {
        ...stepProps1.values,
        ...stepProps2.values,
        password: passwords.password,
        emailVerified: 0,
        realm: '',
        notes: '',
      };
      mutation.mutate(newUser);
    }
  };

  const stepProps3 = {
    passwords,
    isValidated,
    isMatched,
    isValidLength,
    hasSpecialChar,
    handleChange,
    handleSubmit,
    username: stepProps1.values.email
  };

  const renderStep = (id) => {
    switch (id) {
      case 'step1':
        return (
          <Step1
            {...stepProps1}
            setFlow={setFlow}
            errors={stepProps1.errors.email || formErrors.email ?
              {
                email: stepProps1.errors.email || formErrors.email
              } : {}
            }
          />
        );
      case 'step2':
        return (
          <Step2 {...stepProps2} />
        );
      case 'step3':
        return (
          <Step3 {...stepProps3} />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {mutation.isLoading && <Loader fillContainer />}
      {renderStep(id)}
    </>
  );
};

export default EmailFlow;
