import React from 'react';
import classnames from 'classnames';
import PhoneInput from 'react-phone-input-2';
import { Form, Button, ProgressBar, Link, Checkbox } from 'components';
import { ProfessionsList } from 'interfaces';

type Props = {
  values: any,
  errors: any,
  isValidated: boolean,
  handleChange: (event: any) => void,
  handleSelectChange: (key: string, value: any) => void,
  handleSubmit: (event: any) => void,
}
const Step2: React.FC<Props> = ({
  values,
  errors,
  isValidated,
  handleChange,
  handleSelectChange,
  handleSubmit,
}) => {
  return (
    <div className="register__step">
      <h2 className="register__step-title">Create an account</h2>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
          <Form.Input
            className="text-center"
            name="first_name"
            placeholder="First name"
            value={values.first_name || ''}
            isValid={isValidated && Boolean(!errors.first_name)}
            isInValid={isValidated && Boolean(errors.first_name)}
            error={errors.first_name}
            append={<i className="fas fa-address-card"></i>}
            onChange={handleChange}
            round
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Input
            className="text-center"
            name="last_name"
            placeholder="Last name"
            value={values.last_name || ''}
            isValid={isValidated && Boolean(!errors.last_name)}
            isInValid={isValidated && Boolean(errors.last_name)}
            error={errors.last_name}
            append={<i className="fas fa-address-card"></i>}
            onChange={handleChange}
            round
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Select
            className="text-center"
            type="text"
            name="title"
            placeholder="Your profession"
            value={values.title || ''}
            isValid={isValidated && Boolean(!errors.title)}
            isInValid={isValidated && Boolean(errors.title)}
            error={errors.title}
            append={<i className="fas fa-briefcase"></i>}
            onChange={handleChange}
            round
            required
            noArrow
          >
            <option value="">Select Profession</option>
            {Object.entries(ProfessionsList).map(([key, label]) => (
              <option key={key}>{label}</option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-5">
          <PhoneInput
            inputClass={classnames('text-center', {
              'is-valid': isValidated && Boolean(!errors.phone),
              'is-invalid': isValidated && Boolean(errors.phone),
            })}
            country={'us'}
            inputProps={{ required: true }}
            placeholder="Your phone number"
            value={values.phone || ''}
            onChange={(value) => handleSelectChange('phone', value)}
          />
          {errors.phone && (
            <Form.ErrorText>{errors.phone}</Form.ErrorText>
          )}
        </Form.Group>
        <p className="register__step-agreeable-terms">
          <Checkbox
            name="terms"
            id="terms"
            className={classnames('register__step-agreeable-checkbox', {
              'is-valid': isValidated && Boolean(!errors.terms),
              'is-invalid': isValidated && Boolean(errors.terms),
            })}
            onChange={handleChange}
          />
          <label htmlFor="terms">
            By creating your Voicio account you agree to our{' '}
            <Link
              to="#"
              className="register__step-anchor register__step-terms-anchor"
            >
              {'Terms & conditions'}
            </Link>
          </label>
        </p>
        <Button
          variant="secondary"
          large
          round
          className="mb-5"
          sizeX={'182px'}
        >
          Continue&nbsp;&nbsp; <i className="fas fa-long-arrow-alt-right"></i>
        </Button>

      </Form>
      <ProgressBar focus={2} />
    </div>
  );
};

export default Step2;
