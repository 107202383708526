import React from 'react';
import { Form, Link, Button, Checkbox } from 'components';

const Step2 = () => {
  return (
    <div className="register__step">
      <h2 className="register__step-title">Create an account</h2>
      <Form noValidate>
        <p className="register__step-agreeable-terms">
          <Checkbox id="terms" className="register__step-agreeable-checkbox" />
          <label htmlFor="terms">
            By creating your Voicio account you agree to our{' '}
            <Link
              to="#"
              className="register__step-anchor register__step-terms-anchor"
            >
              {'Terms & conditions'}
            </Link>
          </label>
        </p>
        <Button
          variant="secondary"
          type="button"
          large
          round
          className="register__step-btn mb-3"
          sizeX={'182px'}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default Step2;
