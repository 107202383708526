import React from 'react';
import { Form, Button, ProgressBar } from 'components';

type Props = {
  values: any,
  errors: any,
  isValidated: boolean,
  handleChange: (event: any) => void,
  handleSubmit: (event: any) => void,
  setFlow: (flow: string) => void,
}
const Step1: React.FC<Props> = ({
  values, errors, isValidated, handleChange, handleSubmit, setFlow
}) => {
  return (
    <div className="register__step">
      <h2 className="register__step-title">Create an account</h2>
      <h2 className="register__step-subtitle">What is your email address?</h2>
      <Form noValidate onSubmit={handleSubmit}>
        <Form.Group className='mb-4'>
          <Form.Input
            type="email"
            name="email"
            className="text-center"
            placeholder="Email address"
            isValid={isValidated && Boolean(!errors.email)}
            isInValid={isValidated && Boolean(errors.email)}
            error={errors.email}
            value={values.email || ''}
            onChange={handleChange}
            round
            required
          />
        </Form.Group>
        <Button
          variant="secondary"
          large
          round
          className="mb-5"
          sizeX={'182px'}
        >
          Continue&nbsp;&nbsp; <i className="fas fa-long-arrow-alt-right"></i>
        </Button>
      </Form>
      <div className="register__step-signup-option">
        <span
          className="register__step-anchor register__step-signup-option-anchor"
          onClick={() => setFlow('social')}
        >
          Other sign up methods
        </span>
      </div>
      <ProgressBar focus={1} />
    </div>
  );
};

export default Step1;
