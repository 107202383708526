import React from 'react';
import CallingIcon from 'assets/image/icons/register/calling.svg';

const Step2 = () => {
  return (
    <div className="register__step">
      <div className="register__step-calling">
        <CallingIcon />
        <p className="register__step-calling-description">
          We`re giving you a call...
        </p>
        <p className="register__step-calling-number">
          802 0340 4409
        </p>
      </div>
    </div>
  );
};

export default Step2;
